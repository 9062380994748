import '../sass/components/content.scss';
import Link from "react-router-dom/es/Link";

function Contact() {

    return (
        <div className="content-page">
            <h1 className='content-page-title'>Contact Us</h1>
            <div className={'address'}>
                <p>{process.env.REACT_APP_COMPANY_NAME}</p>
                <p>{process.env.REACT_APP_ADDRESS}</p>
            </div>
            <Link to={`tel:${process.env.REACT_APP_PHONE}`}>{process.env.REACT_APP_PHONE}</Link>
            <Link to={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</Link>
        </div>
    )
}

export default Contact
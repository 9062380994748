import {useContext, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {fetchOrders} from "../apiRequests/api";
import Loading from "./Loading";

import "../sass/components/orders.scss"
import {Link} from "react-router-dom";
import DateInput from "./DateInput";

import {refactorDateUs, refactorDate} from "../utils/dateRefactor";
import {SigninContext} from "../contexts/SigninContext";

function Orders() {
    var d = new Date();
    d.setDate(d.getDate() - 30);
    const [fields, setFields] = useState({startDate: d, endDate: refactorDateUs(new Date)})
    const [errors, setErrors] = useState({startDate: false, endDate: false})
    const [orders, setOrders] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const {getIdTokenClaims} = useAuth0()
    const {userName} = useContext(SigninContext)

    async function loadAllOrders() {
        setIsLoading(true)
        let fetchParameters = {
            "orderDateStart": refactorDate(fields.startDate),
            "orderDateEnd": refactorDate(fields.endDate),
            'CustomerAccount': userName
        }
        const token = await getIdTokenClaims()
        const data = await fetchOrders(fetchParameters, token.__raw)
        if (data.error) {
            setOrders(data)
        } else {
            setOrders(data.mainResult)
        }
        setIsLoading(false)
    }

    // date validation and loading orders
    function getOrdersHandler() {
        let isValid = true
        var date_regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        const newErorrs = {...errors}
        let startDate = refactorDateUs(fields.startDate)
        let endDate = refactorDateUs(fields.endDate)
        for (const [key, value] of Object.entries(fields)) {
            if (!(date_regex.test(fields[key]))) {
                newErorrs[key] = true
                isValid = false
            } else {
                newErorrs[key] = false
            }
        }
        isValid = new Date(startDate) > new Date(endDate) ? (
            newErorrs["startDate"] = true,
                false
        ) : true
        setErrors(newErorrs)
        isValid ? loadAllOrders() : alert('Order Date Start must be before Order Date End')
    }

    function handleChange(field, value) {
        const newFields = {...fields};
        newFields[field] = refactorDateUs(value)
        setFields(newFields);
    }

    useEffect(() => {
        loadAllOrders()
    }, [userName])
    return (

        <div className="page orders-page">
            <h2 className='page-title'>My Orders</h2>
            <div className="orders__search">
                <div className="orders__field datepicker">
                    <p>Order Date Start: </p>
                    <DateInput type={"text"}
                               value={refactorDateUs(fields.startDate)}
                               name={"startDate"}
                               setValue={handleChange}
                               error={errors.startDate}
                    />
                </div>
                <div className="orders__field datepicker">
                    <p>Order Date End:</p>
                    <DateInput type={"text"}
                               value={refactorDateUs(fields.endDate)}
                               name={"endDate"}
                               setValue={handleChange}
                               error={errors.endDate}
                    />
                </div>
                <div className="orders__submit_wrapper">
                    <button className='orders__submit' onClick={() => getOrdersHandler()}>Get Orders</button>
                </div>
            </div>
            <Loading isLoading={isLoading}/>
            {!isLoading && userName &&
                <div className="orders__wrapper">
                    {!orders.error &&
                        <div className="orders__list_header">
                            <div className='orders__items_header_inner orders__items_header-order-no'>Order No.</div>
                            <div className='orders__items_header_inner orders__items_header-order-date'>Order Date</div>
                            <div className='orders__items_header_inner orders__items_header-type'>Type</div>
                            <div className='orders__items_header_inner orders__items_header-invoice'>Invoice No.</div>
                            <div className='orders__items_header_inner orders__items_header-reference'>Reference</div>
                            <div className='orders__items_header_inner orders__items_header-nett'>Nett</div>
                            <div className='orders__items_header_inner orders__items_header-vat'>Vat</div>
                            <div className='orders__items_header_inner orders__items_header-view'>View</div>
                            <div className='orders__items_header_inner orders__items_header-reorder'>Re-Order</div>
                        </div>
                    }

                    <div className="orders__list_body">
                        {!orders.error ? orders.map((el, index) => (
                            !el.Order_OrderNo.startsWith('XX') && (
                            <div className="orders__list_row" key={index}>
                            <div className='orders__list_col orders__list_col-order-no'>
                            <span className='orders__list_col_label'>Order No.</span>
                            <Link to={`/order-details/${el.Order_OrderNo}`}><p>{el.Order_OrderNo}</p></Link>
                            </div>
                            <div className='orders__list_col orders__list_col-order-date'>
                            <span className='orders__list_col_label'>Order Date</span>
                            <p>{refactorDate(el.Order_OrderDate)}</p>
                            </div>
                            <div className='orders__list_col orders__list_col-type'>
                            <span className='orders__list_col_label'>Type</span>
                            <p>{el.Order_OrderType}</p>
                            </div>
                            <div className='orders__list_col orders__list_col-invoice'>
                            <span className='orders__list_col_label'>Invoice No.</span>
                            <p>{el.Order_InvoiceNumber}</p>
                            </div>
                            <div className='orders__list_col orders__list_col-reference'>
                            <span className='orders__list_col_label'>Reference</span>
                            <p>{el.Order_CustomerReference}</p>
                            </div>
                            <div className='orders__list_col orders__list_col-nett'>
                            <span className='orders__list_col_label'>Nett</span>
                            <p>{el.Order_TotalNett.toFixed(2)}</p>
                            </div>
                            <div className='orders__list_col orders__list_col-vat'>
                            <span className='orders__list_col_label'>Vat</span>
                            <p>{el.Order_TotalVAT.toFixed(2)}</p>
                            </div>
                            <div className='orders__list_col orders__list_col-view button'>
                            <Link to={`/order-details/${el.Order_OrderNo}`}>View</Link>
                            </div>
                            <div className='orders__list_col orders__list_col-reorder button'>
                            <Link to={`/order-details/${el.Order_OrderNo}`}>Re-Order</Link>
                            </div>
                            </div>
                            )
                        )) : (
                            <div className="error-message">{orders.error}</div>
                        )}
                    </div>
                </div>}
        </div>


    )
}

export default Orders

export const titleField = process.env.REACT_APP_TITLE_FIELD ?? "Description";

/* custom fields for product items on basket, order, product list and product details page */
export const productFields = [
    // for combined values with one icon in one field for example product size - (basket icon)12x75
    {
        combined: [
            {name: "CaseSize"},
            {name: "UnitSize"}
        ],
        iconClass: "fa-light fa-shopping-basket", /* only last class of icon from font-awesome (installed version 4.7) */
        separator: "x", /* use text here, will be used between all combined fields:) */
        append: "cl" /* use text here, will be used between all combined fields:) */
    },
    {name: "Code", iconClass: "fa-light fa-hashtag"},
    {name: "Vintage", iconClass: "fa-light fa-calendar"},
]
/* selects and inputs for product search, u can change order, or add extra fields according to endpoint available parameters,
require to include key of type of field (input or select) with value 'true' */
var filterFields = [
    {title: (!!process.env.REACT_APP_FILTERS_LABEL_DESCRIPTION ? process.env.REACT_APP_FILTERS_LABEL_DESCRIPTION : "Description"),
        name: "description", input: true}
]
if (process.env.REACT_APP_FILTERS_COLOUR === '1') {
    filterFields = [...filterFields,
        {title: (!!process.env.REACT_APP_FILTERS_LABEL_COLOUR ? process.env.REACT_APP_FILTERS_LABEL_COLOUR : "Type"),
            name: "colour", select: true, defaultOption: {value: null, label: "All"}}
    ]
}
if (process.env.REACT_APP_FILTERS_SUPERGROUP === '1') {
    filterFields = [...filterFields,
        {title: (!!process.env.REACT_APP_FILTERS_LABEL_SUPERGROUP ? process.env.REACT_APP_FILTERS_LABEL_SUPERGROUP : "Super Group"),
            name: "supergroup", select:true, defaultOption: {value: null, label: "All"}}
    ]
}
if (process.env.REACT_APP_FILTERS_GROUP === '1') {
    filterFields = [...filterFields,
        {title: (!!process.env.REACT_APP_FILTERS_LABEL_GROUP ? process.env.REACT_APP_FILTERS_LABEL_GROUP : "Product Group"),
            name: "group", select:true, defaultOption: {value: null, label: "All"}}
    ]
}
if (process.env.REACT_APP_FILTERS_COUNTRY === '1') {
    filterFields = [...filterFields,
        {title: (!!process.env.REACT_APP_FILTERS_LABEL_COUNTRY ? process.env.REACT_APP_FILTERS_LABEL_COUNTRY : "Country"),
            name: "country", select:true, defaultOption: {value: null, label: "All"}}
    ]
}
if (process.env.REACT_APP_FILTERS_CLOSURE === '1') {
    filterFields = [...filterFields,
        {title: (!!process.env.REACT_APP_FILTERS_LABEL_CLOSURE ? process.env.REACT_APP_FILTERS_LABEL_CLOSURE : "Closure"),
            name: "closure", select:true, defaultOption: {value: null, label: "All"}}
    ]
}
if (process.env.REACT_APP_FILTERS_ORGANIC_BIO === '1') {
    filterFields = [...filterFields,
        {title: (!!process.env.REACT_APP_FILTERS_LABEL_BIO ? process.env.REACT_APP_FILTERS_LABEL_BIO : "Organic / Biodynamic"),
            name: "organic/biodynamic", select:true, defaultOption: {value: null, label: "All"}}
    ]
}

export var filterFields

export const customerFields = [
    {title: "Customer", name: "customer", select: true},
]
/* required (minimum) qty of bottles */
export const requiredQtyBottles = process.env.REACT_APP_REQUIRED_QTY_BOTTLES ?? 0
export const requiredMinValue = process.env.REACT_APP_REQUIRED_MIN_VALUE ?? 0

// Set closures to override required (minimum) qty of bottles
export const closures = ["KEG", "Bag in Box"]

// setting up color variables as sass variables
document.documentElement.style.setProperty("--brandColor", process.env.REACT_APP_BRAND_COLOR ?? '#782258');
document.documentElement.style.setProperty("--customColor", process.env.REACT_APP_LINK_COLOR ?? '#782258');
document.documentElement.style.setProperty("--opacityBrand", process.env.REACT_APP_BRAND_HOVER_COLOR ?? '#833768e0');
document.documentElement.style.setProperty("--notice", process.env.REACT_APP_NOTICE_COLOR ?? '#AC393B');
document.documentElement.style.setProperty("--error", process.env.REACT_APP_ERROR_COLOR ?? '#c2645a');
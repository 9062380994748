import React from "react";
import basketicon from "../assets/shopping-cart.svg";
import '../sass/header/basket-list.scss'
import {useDispatch, useSelector} from "react-redux";
import closeIcon from "../assets/close.svg";
import {numberWithCommas} from "../utils/extraTools";
import {updateCart} from "../utils/cartTools";
import {Link} from "react-router-dom";
import {titleField} from "../settings";

export default function BasketList () {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    let totalNett = 0
    let totalVAT = 0
    let grandTotal = 0
    //total math
    cart.items&&cart.items.forEach(el => {
        totalNett+=el.CasePrice*el.CasesQty+el.BottlesQty*el.BottleNett
        totalVAT+=el.BottleVAT*(el.CasesQty*el.CaseSize+el.BottlesQty)
        grandTotal = totalNett + totalVAT
    })
    function removeFromCartHandler (index) {
        let product = cart.items[index]
        const newCart = updateCart(product, cart, "REMOVE")
        dispatch({type: "UPDATE_CART", payload: newCart})

    }
    return(
        <div className="busket__wrapper">
            {cart.items.length>0 &&
                <div className="busket__list_header">
                    <div className='busket__items_header_inner busket__items_header-order-name'>Product Name</div>
                    <div className='busket__items_header_inner busket__items_header-order-size'>Case Size</div>
                    <div className='busket__items_header_inner busket__items_header-cases'>Cases</div>
                    <div className='busket__items_header_inner busket__items_header-bottles'>Bottles</div>
                    <div className='busket__items_header_inner busket__items_header-price'>Price</div>
                </div>
            }
            {cart.items.length>0?(
                <div className="busket-list">
                    <div className="busket-list-link">
                        <div className="busket-list-card">
                            {cart.items&&cart.items.map((el,index) => {
                                return (
                                    <div className="busket-list-item" key={index}>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Product Name :</span>
                                            <p>{el[titleField]}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Case Size :</span>
                                            <p>{el.CaseSize}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Cases :</span>
                                            <p>{el.CasesQty}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Bottles :</span>
                                            <p>{el.BottlesQty}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Price :</span>
                                            <p>{window.currency}{el.CasePrice}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <img className="busket-list-item-remove-icon" src={closeIcon} alt="cart-remove-icon" onClick={() => removeFromCartHandler(index)}/>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                        <div className="total-row">
                            <Link to='/basket'><img src={basketicon} className='busket-list-icon' alt="basket-icon" /></Link>

                            <p>{window.currency}{numberWithCommas(grandTotal.toFixed(2))}</p>
                        </div>
                    </div>
                </div>
            ):(
                <div className='busket__empty-mobile'>
                    <img src={basketicon} className='busket-list-icon' alt="basket-icon" />
                    <h3>Your basket is empty</h3>
                    <button className='busket__return-button'><Link to='/product-list' >Return to product list</Link></button>
                </div>
            )}
        </div>
    )
}

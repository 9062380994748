import React, {useState} from "react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import closeIcon from "../assets/close-purple.svg";
import {Link} from "react-router-dom";
import wineWhite from "../assets/Wine-white.png";
import wineRed from "../assets/Wine-red.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import {productFields, titleField} from "../settings";
import Image from "./Image";

export default function BasketItem(props) {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases, setCases] = useState(props.el.CasesQty)
    const [updated, setUpdated] = useState(false)
    const [bottles, setBottles] = useState(props.el.BottlesQty)

    if (process.env.REACT_APP_STOCK_CONTROL === '1') {
        var StockTBottles = +props.el.StockCases * +props.el.CaseSize + +props.el.StockBottles
        var StockTCases = +props.el.StockCases
    } else {
        var StockTBottles = 9999999
        var StockTCases = 9999999
    }

    const [updateClass, setUpdateClass] = useState('')

    // logic for add to cart button
    function addToCartHandler() {
        let currentProduct = props.el
        if (cases > 0 || bottles > 0) {
            let newCases
            let newBottles
            let newTotal = +cases * currentProduct.CaseSize + +bottles

            newCases = Math.floor(newTotal / currentProduct.CaseSize)
            newBottles = newTotal % currentProduct.CaseSize

            if (newTotal > StockTBottles) {
                setCases(StockTCases)
                newCases = StockTCases
                setBottles(StockTBottles % currentProduct.CaseSize)
                newBottles = (StockTBottles % currentProduct.CaseSize)
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }

            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
        changeUpdated(false);
        setUpdateClass('refreshed')
        setTimeout(function () {
            setUpdateClass('')
        }, 500);
    }

    function changeUpdated(new_updated) {
        if (props.handleUpdate) {
            props.handleUpdate(props.el.Code, new_updated);
        }
        setUpdated(new_updated);
    }

    function removeFromCartHandler() {
        let product = props.el
        const newCart = updateCart(product, cart, "REMOVE")
        dispatch({type: "UPDATE_CART", payload: newCart})

    }
    var imageFile = /[^/]*$/.exec(props.el.Image1_URL)[0];
    // Get correct image for product.
    var image = process.env.REACT_APP_VINTNER_IMAGES && props.el.Image1_URL !== "" && !process.env.REACT_APP_URL_TO_IMAGES ? props.el.Image1_URL : props.el.Image1_URL !== "" && process.env.REACT_APP_URL_TO_IMAGES ? process.env.REACT_APP_URL_TO_IMAGES + imageFile
        : (props.el.Type == "White") ? wineWhite
            : (props.el.Type == "Red") ? wineRed : wineWhiteGrey;

    // Set the UnitSize depending on Flag

    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var unit = props.el.CaseSize;
    } else {
        var unit = "1";
    }

    console.log(props.el)
    return (
        <div className="basket-list-item_wrapper">
            <div className="basket-list-item_left">
                {process.env.REACT_APP_IMAGES_JSON ? <Image Alt={props.el[titleField]} ProductCode={props.el.Code} Placeholder={image}/> : <Link to={`/product-detail/${props.el.Code}`}>
                    <img
                        src={image}
                        alt={props.el[titleField]}
                        className="nav-user-profile rounded-circle"
                    />
                </Link>}
            </div>
            <div className="basket-list-item_right">
                <div  onClick={() => removeFromCartHandler()}><i
                    className="fas fa-icon fa-remove cart-remove-icon"/></div>
                {props.el[titleField] !== "" &&
                    <>
                        <Link className='basket-list-item-title-link' to={`/product-detail/${props.el.Code}`}><h2
                            className='basket-list-item-title'>{props.el[titleField]}</h2></Link>
                    </>
                }

                {productFields.map((el, index) => el.name && !el.combined ? (

                        <div key={index} className="basket-list-item_inner">

                            {el.name === "Code" &&
                                <>
                                    <i className={`fas fa-icon ${el.iconClass}`}></i>
                                    {props.el['BinNo'] !== "" && process.env.REACT_APP_BIN === '1' &&
                                        <>{props.el['BinNo']}</>
                                    }
                                    {process.env.REACT_APP_BIN !== '1' &&
                                        <>{decodeURIComponent(props.el['Code'])}</>
                                    }
                                </>
                            }
                            {el.name !== "Code" &&
                                props.el[el.name].length > 1 &&
                                <><i className={`fas fa-icon ${el.iconClass}`}></i>
                                    {props.el[el.name]}
                                </>
                            }
                        </div>
                    ) : (
                        <div key={index} className="basket-list-item_inner">
                            <i className={`fas fa-icon ${el.iconClass}`}></i>

                            {el.combined.map((combinedEl, index) => (

                                <span
                                    key={index}>{props.el[combinedEl.name]}{index < el.combined.length - 1 && el.separator}{index === el.combined.length - 1 && el.append}</span>
                            ))}

                        </div>
                    )
                )}






                <div className="basket-list-item_right__bottom">
                    <>
                        {process.env.REACT_APP_UNIT_FLAG !== "1" || props.el.CaseSize === 1 ? (
                            <div className={"basket-cases"}>

                                <div className="basket_inner">

                                    <div className={"price"}>{props.el.CustomLabel ?? 'Cases'} {window.currency}{props.el.CasePrice.toFixed(2)}</div>
                                    {process.env.REACT_APP_SHOW_STOCK !== "0" &&
                                        <label className="product-list-item-input-label"
                                               htmlFor="stock-cases-input">(<b>Available: </b>{props.el.StockCases})
                                            {process.env.REACT_APP_SHOW_STOCK === '3' &&
                                                <><br/><b>(On Order: </b> {props.el.StockOnOrderCases})</>
                                            }
                                        </label>
                                    }
                                    <div className='product-list-item__qty-row'>
                                        <input type="button" value="-" className="button-minus no_print"
                                               onClick={(function(){setCases(cases > 0 && cases - 1); changeUpdated(true);})} data-field="quantity"
                                        />
                                        <input type="number" id="stock-cases-input" name="stock-cases-input" min="0"
                                               value={cases} placeholder={'0'}
                                               onChange={function(e) {setCases(+e.target.value < 0 ? '' : +e.target.value); changeUpdated(true);}}
                                               max={props.el.StockCases}
                                        />
                                        <input type="button" value="+" className="button-plus no_print"
                                               onClick={(function(){setCases(+cases + 1); changeUpdated(true);})} data-field="quantity"
                                        />
                                    </div>
                                </div>


                            </div>
                        ):('')
                        }
                        {((process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && props.el.OnlySellMultiplesOf <= 1 && process.env.REACT_APP_UNIT_FLAG !== "2") || (!process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && process.env.REACT_APP_UNIT_FLAG !== "2"))  && props.el.CaseSize > 1  ? (
                            <div className={"basket-bottles"}>

                                <div className="basket_inner">

                                    <div className={"price"}><strong>Bottles:</strong> {window.currency}{(Math.round((props.el.BottleNett * 1000) / 10) / 100).toFixed(2)}</div>
                                    {process.env.REACT_APP_SHOW_STOCK !== "0" &&
                                        <label className="product-list-item-input-label"
                                               htmlFor="stock-cases-input">(<b>Available: </b>{props.el.StockBottles})
                                            {process.env.REACT_APP_SHOW_STOCK === '3' &&
                                                <><br/><b>(On Order: </b> {props.el.StockOnOrderBottles})</>
                                            }
                                        </label>
                                    }
                                    <div className='product-list-item__qty-row'>
                                        <input type="button" value="-" className="button-minus no_print"
                                               onClick={(function(){setBottles(bottles > 0 && bottles - 1); changeUpdated(true);})}
                                               data-field="quantity"
                                        />
                                        <input type="number" id="stock-bottles-input" name="stock-bottles-input"
                                               value={bottles} placeholder={'0'}
                                               onChange={function(e) {setBottles(+e.target.value < 0 ? '' : +e.target.value); changeUpdated(true);}}
                                               max={StockTBottles}
                                        />
                                        {bottles < StockTBottles &&
                                            <input type="button" value="+" className="button-plus no_print"
                                                   onClick={(function(){setBottles(+bottles + 1); changeUpdated(true);})} data-field="quantity"
                                            />}
                                        {bottles >= StockTBottles &&
                                            <input type="button" value="+" className="button-plus no_print"
                                                   data-field="quantity"
                                            />}
                                    </div>
                                </div>

                            </div>):(<></>)
                        }
                        <div className={"basket-refresh"}>

                            <button className={`update-cart-btn update-cart-basket-list-table ${updateClass}` + (!updated ? ' disabled' : '')}
                                    onClick={() => addToCartHandler()}
                                    disabled={!updated}>
                                <i className="fa fa-refresh"></i>
                            </button>

                        </div>



                    </>


                </div>
            </div>
        </div>
    )
}
import React, {Fragment} from "react";

import 'reactjs-popup/dist/index.css';

import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchOneProduct} from "../apiRequests/api";
import Loading from "./Loading";
import wineWhite from "../assets/Wine-white.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import wineRed from "../assets/Wine-red.png";
import "../sass/components/order-details.scss";
import "../sass/components/update-cart-btn.scss";
import {useAuth0} from "@auth0/auth0-react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import {productFields, titleField} from "../settings";
import Image from "./Image";

export default function OrderItem(props) {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases, setCases] = useState(props.cases ? props.cases : '')
    const [bottles, setBottles] = useState(props.bottles ? props.bottles : '')
    const [product, setProduct] = useState({})
    var StockTBottles = +product.StockCases * +product.CaseSize + +product.StockBottles
    var StockTCases = +product.StockCases
    const [isLoading, setLoading] = useState(true)
    const [cartUpdateStyles, setCartUpdateStyles] = useState('')
    const [cartAdd, setCartAdd] = useState('')
    const {id} = useParams()
    var prodCode = {
        ProductCode: id ? id : props.id
    }
    const {getIdTokenClaims} = useAuth0();

    // change data regarding to product depending on page
    async function loadProduct() {
        if (id || props.id) {
            const token = await getIdTokenClaims()
            const singleProduct = (await fetchOneProduct(prodCode, token.__raw)).mainResult
            setProduct(singleProduct)
            setLoading(false)
            if (!props.id) cart.items.forEach(el => {
                if (el.Code == singleProduct.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        } else {
            setProduct(props.product)
            cart.items.forEach(el => {
                if (el.Code == props.product.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        }
    }

    function handleKeyPress() {

    }

    function addToCartHandler() {
        let currentProduct
        if (id || props.id) {
            currentProduct = product
        } else {
            currentProduct = props.product
        }

        if (cases > 0 || bottles > 0) {
            let newCases
            let newBottles
            let newTotal = +cases * currentProduct.CaseSize + +bottles

            newCases = Math.floor(newTotal / currentProduct.CaseSize)
            newBottles = newTotal % currentProduct.CaseSize

            if (newTotal > StockTBottles) {
                setCases(StockTCases)
                newCases = StockTCases
                setBottles(StockTBottles % currentProduct.CaseSize)
                newBottles = (StockTBottles % currentProduct.CaseSize)
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }

            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
            setCartAdd('added')
            setTimeout(() => setCartAdd(''), 3000);
            setCartUpdateStyles('refreshed')
            setTimeout(() => setCartUpdateStyles(''), 500);
        } else {
            const newCart = updateCart(currentProduct, cart, "REMOVE")
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
    }

    useEffect(() => {
        loadProduct()
    }, [])

    if (isLoading && id) {
        return <Loading/>;
    }

    // Get correct image for product.
    var image = product.Image1_URL != "" && process.env.REACT_APP_URL_TO_IMAGES != "" ? process.env.REACT_APP_URL_TO_IMAGES + product.Image1_URL
        : (product.Type == "White") ? wineWhite
            : (product.Type == "Red") ? wineRed : wineWhiteGrey;

    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var Unit = product.CaseSize;
    } else {
        var Unit = "1";
    }

    return (
        <Fragment>
            <div class={'add-to-cart'}>
                {process.env.REACT_APP_UNIT_FLAG !== "1" &&

                            <input type="number" id="stock-cases-input" name="stock-cases-input" autoComplete="off"
                                   max="1" value={cases} placeholder={'0'}
                                   disabled={"disabled"}
                            />

                }
                {process.env.REACT_APP_UNIT_FLAG !== "2" &&

                            <input type="number" id="stock-bottles-input" name="stock-bottles-input" autoComplete="off"
                                   value={bottles} placeholder={'0'}
                                   disabled={"disabled"}
                            />

                }


                    {StockTBottles > 0 &&
                        <button className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`}
                                onClick={() => addToCartHandler()}>
                        </button>}
                 </div>

            <div className={"item-title"}>
                {props.lineTitle}
            </div>
            <div className={"item-qty"}>
                {bottles}
            </div>
            <div className={"item-net"}>
                {window.currency}{(Math.round((props.lineNett * 1000) / 10) / 100).toFixed(2)}
            </div>
            <div className={"item-vat"}>
                {window.currency}{(Math.round((props.lineVAT * 1000) / 10) / 100).toFixed(2)}
            </div>

        </Fragment>
    )
}
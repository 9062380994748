import {useParams} from "react-router-dom";
import "../sass/components/content.scss"
import {useDispatch} from "react-redux";

export default function PaymentConfirmed() {
    const dispatch = useDispatch()
    const {sessionID} = useParams()
    dispatch({type: "UPDATE_CART", payload: []})
    return (
        <div className="content-page">
            <h1 className='content-page-title'>Order Confirmed</h1>
            <div className={"confirmation"}>
                <div className={"confirmation-message"}>Thank you for your order and payment</div>
            </div>
        </div>
    )
}
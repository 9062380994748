const defaultState = {
    auth: false
}

export const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "ALLOW_ACCESS":
            return {...state, auth: state.auth = true}

        case "DENY_ACCESS":
            return {...state, auth: state.auth = false}

        default:
            return state
    }
}
import ProductList from "./ProductList";

function ProductListPage () {
    return (
        <div className='product-list-wrapper'>
            <h1 className='page-title'>My Products</h1>
            <ProductList/>
        </div>
    )
}
export default ProductListPage
import React from 'react';
import { Page, Text, Image, Link, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import logo from "../logo.webp";
import font from "../fonts/old-fonts/Montserrat-Regular.ttf"

Font.register({
    family: "Montserrat",
    format: "truetype",
    src: font
});

// Create styles
const styles = StyleSheet.create({
    page: {
        height: 842,
        backgroundColor: '#FFFFFF',
        fontFamily:  'Montserrat',
        fontSize: 7,
        marginHorizontal: 60,
        marginVertical: 15
    },
    header: {
        flexDirection: 'row'
    },
    content: {
        marginTop: 40,
        flexDirection: 'row'
    },
    footer: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: 40
    },
    headerLeft: {
        width: 400
    },
    headerRight: {
        width:245
    },
    logo: {
        width: 150,
    },
    contentLeft: {
        width:150
    },
    contentRight: {
        width:310,
        marginLeft:20
    },
    footerLeft: {
        width: 270
    },
    footerRight: {
        width: 200,
        alignContent: 'flex-end'
    },
    title: {
        fontSize: 16,
        paddingBottom: 20,
        fontWeight: 'Bold',
    },
    price: {
        fontSize: 12,
        paddingTop: 10,
        paddingBottom: 20,
        fontWeight: 'Bold',
    },
    subtitle: {
        fontSize: 9,
        width:310
    },
    subtitle2: {
        fontSize: 9,
        width:310,
        paddingBottom: 10,
        marginTop: -10
    },
    notes: {
        paddingTop: 10,
        width:310
    },
    fields: {
        paddingTop: 10
    },
    right: {
        textAlign: 'right'
    },
    field: {
        paddingRight: 5
        },
    lastField: {
    }
});

const Br = () => "\n";

// Create Document Component
const TastingNotes = (props) => (

    <Document title={props.product.Code + " " + props.product.SecondaryDescription}>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.headerLeft}>
                    <Image style={styles.logo} src={logo}>
                    </Image>
                </View>
                <View style={styles.headerRight}>
                    <Text>Wines Direct Ireland</Text>
                    <Text>49 Lough Sheever Corporate Park</Text>
                    <Text>Mullingar</Text>
                    <Text>Co.Westmeath</Text>
                    <Text>N91 V658</Text>
                    <Text style={styles.fields}>+353 818 579 579</Text>
                    <Link style={styles.fields} src={'mailto:sales@winesdirect.ie'}>sales@winesdirect.ie</Link>
                </View>
            </View>
            <View style={styles.content}>
                <View style={styles.contentLeft}>
                    <Image style={styles.logo} src={props.image}>
                    </Image>
                    <Text style={styles.fields}>Provenance: {props.product.CustomListItem6}, {props.product.CountryDescription}</Text>
                    <Text style={styles.fields}>Type: {props.product.Type}</Text>
                    <Text style={styles.fields}>Style: {props.product.Style}</Text>
                    <Text style={styles.fields}>ABV: {props.product.AlcoholicPercentage}%</Text>
                    <Text>{props.suitableGroup.length ? (
                        <Text style={styles.fields}><Br />Dietary:
                            {props.suitableGroup.map((item, i) => (
                                <Text key={i}> {item}{(i === props.suitableGroup.length - 1) ? '' : ' / '}</Text>
                            ))}
                        </Text>) : ""}
                    </Text>
                    <Text>{props.allergenGroup.length ? (
                        <Text style={styles.fields}>Contains:
                            {props.allergenGroup.map((item) => (
                                <Text> {item} /</Text>
                            ))}
                        </Text>) : ""}
                    </Text>
                    <Text style={styles.fields}>Excise Duty: {props.product.ExciseDescription}</Text>
                </View>
                <View style={styles.contentRight}>
                    <Text style={styles.title}>{props.product.SecondaryDescription.toUpperCase()}</Text>
                    <Text>{props.product.Code}</Text>
                    <Text style={styles.price}>{window.currency} {(Math.round((props.product.BottleNett * 1000) / 10) / 100).toFixed(2)}</Text>
                    <Text style={styles.subtitle}>Tasting Notes</Text>
                    <Text>{props.notesGroup.length ? (
                      <Text>{props.notesGroup.map((item) => (
                                <Text><Br /><Br />{item}</Text>
                        ))}
                        </Text>) : ""}
                    </Text>
                    <Text style={styles.subtitle2}>Wine Making Notes</Text>
                    <Text>{props.wineMakingNotes}</Text>
                    <Text>
                        {props.suggestionGroup.length ? (
                            <div>
                               {props.suggestionGroup.map((item) => (
                                   <Text>{item}</Text>
                                ))}
                            </div>) : ""}
                    </Text>
                </View>
            </View>
            <View style={styles.footer}>
                <View style={styles.footerLeft}>
                    <Text>Information correct as of {new Date().toLocaleString() + ""}</Text>
                    <Text>© {new Date().getFullYear()} All rights reserved</Text>
                </View>
                <View style={styles.footerRight}>
                    <Text style={styles.right}>Privacy Policy and Terms & Conditions are available at</Text>
                    <Link style={styles.right} src={'https://trade.winesdirect.ie'}>https://trade.winesdirect.ie</Link>
                </View>
            </View>
        </Page>
    </Document>
);

export default TastingNotes;
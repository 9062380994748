import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { Auth0Provider } from "@auth0/auth0-react";
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import {store, persistor} from "./reducers/configureStore";
import history from "./utils/history";

import { getConfig } from "./authentification/config";
import reportWebVitals from './reportWebVitals';

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    onRedirectCallback,
};


ReactDOM.render(
  <React.StrictMode>
      <Auth0Provider {...providerConfig}>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <App />
              </PersistGate>
          </Provider>
      </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Global Variables from ENV

switch(process.env.REACT_APP_CURRENCY) {
    case "EUR":
        window.currency = "\u20AC";
        break;
    default:
        window.currency = "\u00A3";
}
import React from "react";
import "../sass/components/loading.scss";

const Loading = (props) => (
    <div className={'container'}>
        <div className={`lds-ring ${props.isLoading ? '' : 'hide'}`}>

            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Loading;

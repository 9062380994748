import {Link} from "react-router-dom";
import React from "react";

function Image(props) {

    let images;
    let imageUrl = props.Placeholder;
    try {
        images = require('../images.json');
        if (images.find(t => t.ProductCode === props.ProductCode)) {
            imageUrl = images.find(t => t.ProductCode === props.ProductCode).CDN;
        }
    } catch (err) {
        // Empty catch to avoid excessive logging
     }

    return (
        <>
            {imageUrl &&
                <Link to={`/product-detail/${props.ProductCode}`}>
                    <img
                        src={imageUrl}
                        alt={props.Alt}
                        className="nav-user-profile rounded-circle"
                    />
                </Link>}
        </>
    )
}

export default Image
import '../sass/components/content.scss';
import DOMPurify from 'isomorphic-dompurify';
import Link from "react-router-dom/es/Link";

function TermsConditions() {

    var content = {};
    if (process.env.REACT_APP_TERMS !== "") {
        content = require('../'+ process.env.REACT_APP_TERMS);
    } else {
        content = require('../default.terms.json');
    }
    return (
        <div className="content-page">
            <h1 className='content-page-title'>Terms & Conditions</h1>
            {/* Use DOMPurify to make this safe */}
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content.data, {ALLOWED_TAGS: ['table', 'td', 'tr','br', 'p', 'span', 'Link', 'h4', 'ul', 'li', 'a'], ALLOWED_ATTR: ['style', 'href']}) }} />
            <div className={'contact'}>
                <Link to={'/contact'}><button>Contact Us</button></Link>
            </div>

        </div>
    )
}
export default TermsConditions
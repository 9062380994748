
import { useAuth0 } from "@auth0/auth0-react";


export const LogoutButton = () => {
    const { logout } = useAuth0();

    function logoutUser () {
        // dispatch({type: "CHANGE_ACCESS", payload: false})
        logout({ returnTo: window.location.origin })
    }
    return (
        <button className='logout-btn' onClick={() => logoutUser()}>
            Log Out
        </button>
    );
};
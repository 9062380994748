const defaultState = {
    items: []
}
// basic reducer for cart to update it
export const cartReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "UPDATE_CART" :
            return {...state, items: action.payload}

        case "CLEAR_CART" :
            return {...state, items: action.payload}

        default:
            return state
    }
}
import {useParams} from "react-router-dom";
import "../sass/components/content.scss"
import {useDispatch} from "react-redux";
import {useAuth0} from "@auth0/auth0-react";

export default function PaymentConfirmedAnon() {
    const dispatch = useDispatch()
    const {sessionID} = useParams()
    const { loginWithRedirect } = useAuth0();
    dispatch({type: "UPDATE_CART", payload: []})
    return (
        <div className="content-page">
            <h1 className='content-page-title'>Order Confirmed</h1>
            <div className={"confirmation-message"}>Thank you for your order and payment</div>
            <div className={"confirmation"}>
                <div>
                    <p>Your session has expired and you have been logged out. Please login again to place another order.</p>
                    <button className='login-btn' onClick={ () => loginWithRedirect()}>Log in</button>
                </div>
            </div>
        </div>
    )
}
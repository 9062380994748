import React from "react";
import basketicon from "../assets/shopping-cart.svg";
import '../sass/header/basket-list-checkout.scss'
import {useDispatch, useSelector} from "react-redux";
import closeIcon from "../assets/close.svg";
import {numberWithCommas} from "../utils/extraTools";
import {updateCart} from "../utils/cartTools";
import {Link} from "react-router-dom";
import {titleField} from "../settings";

export default function BasketList() {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    let totalNett = 0
    let totalVAT = 0
    let grandTotal = 0
    //total math
    cart.items && cart.items.forEach(el => {
        totalNett += el.CasePrice * el.CasesQty + el.BottlesQty * el.BottleNett
        totalVAT += el.BottleVAT * (el.CasesQty * el.CaseSize + el.BottlesQty)
        grandTotal = totalNett + totalVAT
    })

    function removeFromCartHandler(index) {
        let product = cart.items[index]
        const newCart = updateCart(product, cart, "REMOVE")
        dispatch({type: "UPDATE_CART", payload: newCart})

    }

    return (
        <div className="busket__wrapper">
            {cart.items.length > 0 &&
                <div className="busket__list_header">
                    <div className='busket__items_header_inner busket__items_header-order-name'>Item</div>
                    <div className='busket__items_header_inner busket__items_header-order-size'>Size</div>
                    <div className='busket__items_header_inner busket__items_header-cases'>Cs</div>
                    <div className='busket__items_header_inner busket__items_header-bottles'>Bts</div>
                    <div className='busket__items_header_inner busket__items_header-bottles'>{window.currency}</div>
                </div>
            }
            {cart.items.length > 0 ? (
                <div className="busket-list">
                    <div className="busket-list-link">
                        <div className="busket-list-card">
                            {cart.items && cart.items.map((el, index) => {
                                return (
                                    <div className="busket-list-item" key={index}>
                                        <div
                                            className='busket-list-item_col busket-list-item_col-order-no busket-list-item-title'>
                                            <span className='busket-list-item_col_label'>Item</span>
                                            <p>{el[titleField]}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Size</span>
                                            <p>{el.CaseSize}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Cases</span>
                                            <p>{el.CasesQty}</p>
                                        </div>
                                        <div className='busket-list-item_col busket-list-item_col-order-no'>
                                            <span className='busket-list-item_col_label'>Bottles</span>
                                            <p>{el.BottlesQty}</p>
                                        </div>
                                        <div
                                            className='busket-list-item_col busket-list-item_col-order-no busket-list-item-amount'>
                                            <span className='busket-list-item_col_label'>Amount</span>
                                            <p>{window.currency}{(el.CasePrice * el.CasesQty + el.BottlesQty * el.BottleNett).toFixed(2)}</p>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="busket-list-item busket-list-summary">
                                <div
                                    className='busket-list-item_col busket-list-item_col-order-no busket-list-item-title'>
                                    <div className={"total"}>Sub Total</div>

                                    <div className={"total-amount"}>{window.currency}{(totalNett).toFixed(2)}</div>
                                </div>
                            </div>

                            <div className="busket-list-item busket-list-summary">
                                <div
                                    className='busket-list-item_col busket-list-item_col-order-no busket-list-item-title'>
                                                         <div className={"total"}>VAT</div>

                                    <div className={"total-amount"}>{window.currency}{(totalVAT).toFixed(2)}</div>
                                </div>
                            </div>

                            <div className="busket-list-item busket-list-summary busket-list-total">
                                <div
                                    className='busket-list-item_col busket-list-item_col-order-no busket-list-item-title'>
                                    <div className={"total"}>Order Total</div>

                                    <div className={"total-amount"}>{window.currency}{(grandTotal).toFixed(2)}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

import { fetchPostAppSettings} from '../apiRequests/api';
import {useAuth0} from "@auth0/auth0-react";
import { React, useEffect, useState } from 'react';
import { useAppSettings } from '../hooks/useAppSettings';
import Loading from './Loading';
import "../sass/components/admin.scss"
import { useHistory } from "react-router-dom";
import ProductListPage from './ProductListPage';
import ProductList from './ProductList';

export default function AdminSettings(props) {
  const settings = useAppSettings();
  const [formData, setFormData] = useState(settings);
  const {getIdTokenClaims} = useAuth0();
  let history = useHistory();

  async function handleSubmit(event) {
    event.preventDefault();
    const token = await getIdTokenClaims();
    const data = await fetchPostAppSettings(formData, token.__raw);
    alert('App settings saved!');
    window.location.reload();
  }

  function handleChange (event) {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }

  useEffect(() => {
    if (!settings['__empty'] && !settings['__is_admin_user']) {
      history.push("/product-list");
    } else {
      setFormData(settings)
    }
  }, [settings])
  return (
    <div>
      {settings['__empty'] ?
        <div className='product-list-wrapper'>
          {/*Imitate frontpage */}
          <h1 className='page-title'>My Products</h1>
          <Loading isLoading={true}/>
        </div>
        :
        <div className="page orders-page admin">
          <h2 className='page-title v2'>Trade App Settings</h2>
          <div className='admin__note'>
            Customise your Trade App using the options below.
          </div>
          <form className='admin__form' onSubmit={handleSubmit}>

            <fieldset className='admin__form-field section'>
              <h3 className='admin__form-label'>Banner</h3>
              <p>Add a html code to display a banner above the Title on all
                pages. For Elfsight banners paste only the HTML content part
                of the widget, ignoring the code within the "script" "tags</p>
              <textarea className='admin__form-textarea'
                        value={formData['banner']} id="banner" name="banner"
                        onChange={handleChange}/>
              <input className='admin__form-submit' type="submit"
                     value="Update Banner"/>
            </fieldset>

          </form>
        </div>
      }
    </div>
  );
}
import { useEffect, useState } from 'react';
import { fetchAppSettings } from '../apiRequests/api';
import { useAuth0 } from '@auth0/auth0-react';

export const useAppSettings = () => {
  const {getIdTokenClaims} = useAuth0()
  const [appSettings, setAppSettings] = useState({__empty: true});

  useEffect(() => {
    const fetchSettings = async () => {
      const token = await getIdTokenClaims();
      const data = await fetchAppSettings(token.__raw);
      setAppSettings(data);
    };
    fetchSettings();
  }, [setAppSettings]);

  return appSettings;
};
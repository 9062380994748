import '../sass/header/burgermenubutton.scss'
import '../sass/header/burgermenupopup.scss';
import '../sass/header/menu.scss';
import {useState} from "react";
import Menu from "./Menu";
import OutsideAlerter from "../hooks/useOutsideClick";

function BurgerMenuButton () {
    const [navMenu, setNavMenu] = useState(false)

    function toggleMenu() {
        setNavMenu(!navMenu)
    }
    return (
        <OutsideAlerter setAction={setNavMenu}>
            <div className="mobile-hamburger-btn">
                <button onClick={() => toggleMenu()} className="navbar_toggler" type="button" >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div className={`mobile-burgermenu-popup ${navMenu&&'opened'}`}>
                <Menu toggleMenu={toggleMenu}/>
            </div>
        </OutsideAlerter>
    )
}
export default BurgerMenuButton
import React from 'react'
import "../sass/components/totals.scss";
import {numberWithCommas} from "../utils/extraTools";

const Totals = ({totalNett, totalVAT, grandTotal}) => {
    return (
        <div className='total-block'>
            <div className='total-col'>
                <div className="total-col__row">
                    <h4>Total Nett</h4>
                    <h3>{window.currency}{numberWithCommas(totalNett.toFixed(2))}</h3>
                </div>
                <div className="total-col__row">
                    <h4>Total VAT</h4>
                    <h3>{window.currency}{numberWithCommas(totalVAT.toFixed(2))}</h3>
                </div>
                <div className="total-col__row">
                    <h4>Grand Total</h4>
                    <h3>{window.currency}{numberWithCommas(grandTotal.toFixed(2))}</h3>
                </div>
            </div>
        </div>
    )
}

export default Totals

import {Link, useParams} from "react-router-dom";

import "../sass/components/processing.scss"
import {useDispatch} from "react-redux";
import {ReactSession} from "react-client-session";
import {fetchCustomer, fetchPaymentVintner, fetchOneOrderEmail, fetchConfirmOrder} from "../apiRequests/api";
import {useAuth0} from "@auth0/auth0-react";
import {useContext, useEffect, useState} from "react";
import {SigninContext} from "../contexts/SigninContext";
import Loading from "./Loading";

export default function ProcessingOrder() {
    const {orderId} = useParams()
    const {backOrderId} = useParams()
    const {reservesOrderId} = useParams()
    const [confirmedOrderId, setConfirmedOrderId] = useState(orderId)
    const [confirmedBackOrderId, setConfirmedBackOrderId] = useState(backOrderId)
    const [confirmedReservesOrderId, setConfirmedReservesOrderId] = useState(reservesOrderId)
    const [orderStatus, setOrderStatus] = useState("Processing")
    const [backOrderStatus, setBackOrderStatus] = useState("Processing")
    const [reservesOrderStatus, setReservesOrderStatus] = useState("Processing")
    const {getIdTokenClaims} = useAuth0()
    const {userName} = useContext(SigninContext)
    const [isLoading, setIsLoading] = useState(true)
    const [confirmed, setConfirmed] = useState(false)

    async function confirmOrder() {
        if (orderId && orderId !== '0' && sessionStorage.getItem(orderId) !== '1') {
            const token = await getIdTokenClaims()
            if (orderId.startsWith("XX")) {
                const order = await fetchConfirmOrder(orderId, userName, token.__raw)
                if (order.mainResult) {
                    setConfirmedOrderId(order.mainResult)
                    setOrderStatus('Confirmed')

                }
            }
        }
        if (backOrderId && backOrderId !== '0' && sessionStorage.getItem(backOrderId) !== '1') {
            const token = await getIdTokenClaims()
            if (backOrderId.startsWith("XX")) {
                const backOrder = await fetchConfirmOrder(backOrderId, userName, token.__raw)
                if (backOrder.mainResult) {
                    setConfirmedBackOrderId(backOrder.mainResult)
                    setBackOrderStatus('Confirmed')

                }
            }
        }
            if (reservesOrderId && reservesOrderId !== '0' && sessionStorage.getItem(reservesOrderId) !== '1') {
                const token = await getIdTokenClaims()
                if (reservesOrderId.startsWith("XX")) {
                    const reservesOrder = await fetchConfirmOrder(reservesOrderId, userName, token.__raw)
                    if (reservesOrder.mainResult) {
                        setConfirmedReservesOrderId(reservesOrder.mainResult)
                        setReservesOrderStatus('Confirmed')
                    }
                }


            }
            setConfirmed(true)
        }

    async function sendOrderEmail(orderID,hasBackOrder) {
        const token = await getIdTokenClaims()
        await fetchOneOrderEmail(orderID, userName, token.__raw, 'Order')
        sessionStorage.setItem(orderId, '1');
    }
    async function sendBackOrderEmail(orderID, hasBackOrder) {
        const token = await getIdTokenClaims();
        await fetchOneOrderEmail(orderID, userName, token.__raw, 'Back Order');
        sessionStorage.setItem(backOrderId, '1');
    }
    async function sendReservesOrderEmail(orderID, hasBackOrder) {
        const token = await getIdTokenClaims();
        await fetchOneOrderEmail(orderID, userName, token.__raw, 'Reserves Order');
        sessionStorage.setItem(reservesOrderId, '1');
    }

    useEffect(() => {
            confirmOrder()
    }, [])
    useEffect(() => {
        if (orderStatus === 'Confirmed' && sessionStorage.getItem(orderId) !== '1') {
            sendOrderEmail(confirmedOrderId)
        }
        if (backOrderStatus === 'Confirmed' && sessionStorage.getItem(backOrderId) !== '1') {
            sendBackOrderEmail(confirmedBackOrderId)
        }
        if (reservesOrderStatus === 'Confirmed' && sessionStorage.getItem(reservesOrderId) !== '1') {
            sendReservesOrderEmail(confirmedReservesOrderId)
        }
    }, [confirmed])



    return (
        <div className="page processing-page">

            <div className={'processing-bar'}>

                {orderId.length > 1 &&
                    <p className={orderStatus}> {orderStatus} Order: {confirmedOrderId}</p>
                }
                {orderId.length === 1 && backOrderId.length === 1 && reservesOrderId.length > 1 &&
                    <p>All items have been moved to Reserves Order: {confirmedReservesOrderId}</p>
                }
                {((orderId.length > 1 || backOrderId.length > 1) && reservesOrderId.length > 1) ? (
                        <p>Some items have been moved to Reserves Order: {confirmedReservesOrderId}</p>
                    ):
                    <></>
                }
                {orderId.length > 1 && backOrderId.length > 1 &&
                    <p>Not all Stock available. Some items have been moved to an additional Back Order: {confirmedBackOrderId}</p>
                }

                {orderId.length === 1 && backOrderId.length > 1 && reservesOrderId.length > 1 &&
                    <p>Not all Stock available. Some items have been moved to Back Order: {confirmedBackOrderId}</p>
                }

                {orderId.length === 1 && backOrderId.length > 1 && reservesOrderId.length === 1 &&
                    <p>No Stock. All items have been moved to Back Order: {confirmedBackOrderId}</p>
                }


            </div>
        </div>
    )
}
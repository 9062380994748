import '../sass/components/footer.scss';
import oiLogo from "../assets/open-imagination.svg";
import vintnerLogo from "../assets/Vintner-Logo.png";
import React from "react";
import {Link} from "react-router-dom";

function Footer () {
    return (
        <footer className={'footer'}>
            <div className="container">
                {process.env.REACT_APP_FOOTER_BRANDING === '1' &&
                    <div className="footer-top">
                        <a href="https://www.openimagination.co.uk/" target="_blank"
                           className="footer-logo footer-logo_left">
                            <img src={oiLogo} alt="Open Imagination logo"/>
                        </a>
                        <a href="http://www.vintner.co.uk/" target="_blank" className="footer-logo footer-logo_right">

                            <img src={vintnerLogo} alt="Vintner logo"/>
                        </a>
                    </div>
                }
                <div className="footer-bottom">
                    <div className="footer-bottom_inner_top">
                        <p className={'copyright'}>&copy; {new Date().getFullYear()} All rights reserved.</p>
                    </div>

                    <div className="footer-bottom_inner_bottom">
                        <Link className="footer-link" to="/privacy">Privacy</Link>
                        <Link className="footer-link" to="/terms-conditions">Terms & Conditions</Link>
                        <Link className="footer-link" to="/contact">Contact Us</Link>
                    </div>
                </div>

            </div>
        </footer>
    )
}
export default Footer
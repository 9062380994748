
export const updateCart = (product, cart,action, cases, bottles) => {
    switch (action) {
        case "REMOVE" : {
            let updatedCart = cart.items
            updatedCart = updatedCart.filter(el=> (
                (el.Code !== product.Code)&&el
            ))
            return updatedCart
        }
        case "ADD" : {
            let productPayload = product
            productPayload.CasesQty = cases
            productPayload.BottlesQty = bottles

            let updatedCart = cart.items
            if (updatedCart.find(el=>el.Code === productPayload.Code)){
                updatedCart.forEach(elem=>{
                    if(elem.Code === productPayload.Code) {
                        elem.CasesQty = productPayload.CasesQty
                        elem.BottlesQty = productPayload.BottlesQty
                    }
                })
            }else {
                updatedCart.push(productPayload)
            }
            return updatedCart
        }
        default:
            return cart.items
    }

}